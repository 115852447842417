import(/* webpackMode: "eager" */ "/home/msservice/public_html/fe-siam/src/app/[lng]/(home)/components/Mobile/components/Renderer/RenderCateItemGames.tsx");
import(/* webpackMode: "eager" */ "/home/msservice/public_html/fe-siam/src/app/[lng]/(home)/components/Mobile/components/Renderer/RenderProviderGames.tsx");
import(/* webpackMode: "eager" */ "/home/msservice/public_html/fe-siam/src/app/[lng]/(home)/components/Mobile/components/SportGames/SportGames.tsx");
import(/* webpackMode: "eager" */ "/home/msservice/public_html/fe-siam/src/app/i18n/client.ts");
import(/* webpackMode: "eager" */ "/home/msservice/public_html/fe-siam/src/components/core/Link/Link.tsx");
import(/* webpackMode: "eager" */ "/home/msservice/public_html/fe-siam/src/components/modules/Layouts/DesktopLayout/Footer/DesktopFooter.tsx");
import(/* webpackMode: "eager" */ "/home/msservice/public_html/fe-siam/src/components/modules/Layouts/DesktopLayout/Header/DesktopHeader.tsx");
import(/* webpackMode: "eager" */ "/home/msservice/public_html/fe-siam/src/components/modules/Layouts/MobileLayout/Header/MobileHeader.tsx");
import(/* webpackMode: "eager" */ "/home/msservice/public_html/fe-siam/src/components/modules/Menu/FooterMobile.tsx");
import(/* webpackMode: "eager" */ "/home/msservice/public_html/fe-siam/src/components/modules/Page/Page.tsx");
import(/* webpackMode: "eager" */ "/home/msservice/public_html/fe-siam/src/constant/gameConfig.ts")